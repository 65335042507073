
export default {
  "当前语言": "日本語",
  "语言": 'jpn',
  "马上匹配": "今すぐマッチ",
  "我们致力于为高端单身人群，提供更好的配对服务": "私たちはハイエンドのシングルにより良いマッチングサービスを提供することに取り組んでいます",
  "为你寻找到适合你的人生伴侣": "あなたの人生のパートナーを見つける",
  "我们已经成功为": "私たちはすでに成功裏に",
  "人成功找到幸福": "個人を彼らのソウルメイトとマッチングさせました",
  "欢迎注册": "登録へようこそ",
  "完成相关信息后快速匹配您心仪的伴侣": "関連情報を入力後、理想のパートナーと迅速にマッチング",
  "国家": "国",
  "请选择你的国家": "あなたの国を選択してください",
  "地区": "地域",
  "年龄": "年齢",
  "请选择你的地区": "あなたの地域を選択してください",
  "请输入你的年龄": "あなたの年齢を入力してください",
  "性别": "性別",
  "请选择你的性别": "あなたの性別を選択してください",
  "工作收入": "収入",
  "请选择你的年收入": "あなたの年収を選択してください",
  "电话": "電話",
  "请输入你的电话": "電話番号を入力してください",
  "邮箱": "メール",
  "请输入你的邮箱": "メールアドレスを入力してください",
  "邮箱验证码": "メール確認コード",
  "验证码": "確認コード",
  "发送验证码": "コード送信",
  "秒后获取": "秒後取得",
  "密码": "パスワード",
  "请输入你的密码": "パスワードを入力してください",
  "请再次输入你的密码": "もう一度パスワードを入力してください",
  "确认密码": "パスワードを確認",
  "快速匹配": "速マッチ",
  "已有账号，去登录": "すでにアカウントをお持ちの方はこちらからログイン",
  "你的邮箱不正确": "あなたのメールが正しくありません",
  "发送成功": "送信成功",
  "请输入邮箱验证码": "メール確認コードを入力してください",
  "密码不能少于6位数": "パスワードは6文字以上でなければなりません",
  "请输入你的确认密码": "確認用のパスワードを入力してください",
  "注册成功": "登録成功",
  "登录成功": "ログイン成功",
  "恭喜您选择了心仪的伴侣": "理想のパートナーを選んだことをおめでとうございます",
  "姓名": "名前",
  "生日": "誕生日",
  "身高": "身長",
  "兴趣": "興味",
  "获取WS号": "WS取得",
  "复制": "コピー",
  "已成功为您匹配到合适的伴侣": "あなたに適したパートナーを成功裏にマッチングしました",
  "请选择伴侣": "パートナーを選んでください",
  "确认选择后将无法修改": "選択を確認後は変更できません",
  "首页": "ホーム",
  "网站使用条款": "利用規約",
  "隐私政策": "プライバシーポリシー",
  "法律条款": "法的条項",
  "Copyright © XXXX集团 版权所有": "Copyright © XXXXグループ. 無断複製・転載を禁じます。",
  "没有账号，去注册": "アカウントをお持ちでない方はこちらから登録",
  "男": "男性",
  "女": "女性",
  "他": "彼",
  "她": "彼女",
  "确认选择": "確認",
  "确认": "確認",
  "取消": "キャンセル",
  "复制成功": "コピー成功",
  "复制失败": "コピー失敗",
  "他的WS号": "彼のWS番号",
  "她的WS号": "彼女のWS番号",
  "服务协议": "サービス利用規約",
  "隐私保护政策": "プライバシーポリシー",
  "关于我们": "私たちについて",
  "客服邮箱":"カスタマーサポートメール",
}

