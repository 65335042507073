import "core-js/modules/es.array.push.js";
import Footer from '../components/Footer.vue';

import { showToast, showConfirmDialog } from 'vant/es';
export default {
  components: {
    Footer
  },
  data() {
    return {
      setId: 0,
      switch1Init: false,
      userInfo: null,
      lists: [],
      findId: 0
    };
  },
  created() {
    this.getInfo();
    this.getLoves();
  },
  methods: {
    setMember() {
      if (this.setId == 0) {
        showToast(this.$L('请选择伴侣'));
        return false;
      }
      showConfirmDialog({
        message: this.$L('确认选择后将无法修改'),
        confirmButtonText: this.$L('确认'),
        cancelButtonText: this.$L('取消')
      }).then(() => {
        console.log('sss');
        // on confirm
        this.$api.setLove({
          love_id: this.setId
        }, res => {
          if (res.code == 0) {
            showToast(this.$L('选择成功'));
            this.$router.push('/choice');
          } else {
            showToast(res.msg);
          }
        });
      }).catch(() => {
        // on cancel
      });
    },
    getInfo() {
      this.$api.getUserInfo({}, res => {
        if (res.code == 0) {
          this.userInfo = res.data;
          if (this.userInfo.loveFind) {
            this.findId = this.userInfo.loveFind['love_user_id'];
          }
        } else {
          showToast(res.msg);
        }
      });
    },
    getLoves() {
      this.$api.getLoves({}, res => {
        if (res.code == 0) {
          this.lists = res.data;
        } else {
          showToast(res.msg);
        }
      });
    }
  }
};