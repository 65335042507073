import "core-js/modules/es.array.push.js";
import { showToast } from 'vant';
import Footer from '../components/Footer.vue';
export default {
  components: {
    Footer
  },
  data() {
    return {
      popupShow: false,
      info: false,
      ws: ''
    };
  },
  created() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      this.$api.getLoveInfo({}, res => {
        if (res.code == 0) {
          this.info = res.data;
        } else {
          this.$router.push('/matching');
        }
      });
    },
    getWs() {
      this.$api.getWs({}, res => {
        if (res.code == 0) {
          this.ws = res.data;
          this.popupShow = true;
          window.open('https://wa.me/' + this.ws, '_blank');
        } else {
          this.$router.push('/matching');
        }
      });
    }
  }
};