import $F from './func'
import $L from './lang'
import $api from '../request/api'
/**
 *  注册全局挂载对象
 */
export default{
    init(app){
        app.config.globalProperties.$F = $F
        $L.init();
        app.config.globalProperties.$Lang = $L
        app.config.globalProperties.$L = $L.get
        app.config.globalProperties.$api = $api
    }
}