import "core-js/modules/es.array.push.js";
/**
 * 系统函数库
 */
export default {
  app: null,
  init(app) {
    this.app = app;
  },
  /**
   * 跳转页面方法 + 拦截逻辑
   * @param {*} path 
   */
  go(path) {
    //如果你没有登录，那么跳转登录
    this.app.$router.push(path);
  },
  copyToClipboard(text) {
    // 检查浏览器是否支持 Clipboard API
    if (!navigator.clipboard) {
      return fallbackCopyTextToClipboard(text);
    }
    return navigator.clipboard.writeText(text).then(() => {
      showToast(this.app.$L('复制成功'));
    }, err => {
      showToast(this.app.$L('复制失败'));
    });
  },
  /**
   * 模仿PHP的date()函数
   * strtotime('Y-m-d H:i:s');
   * @param format 只支持 'Y-m-d H:i:s','Y-m-d','H:i:s' 三种调用方式
   * @param time 为空时，取当前时间
   * @return 日期格式化的字符串
   */
  date(format, timestamp) {
    const d = timestamp ? new Date(timestamp * 1000) : new Date(); // 将秒转换为毫秒
    const year = d.getFullYear();
    const month = d.getMonth() + 1;
    const day = d.getDate();
    const hour = d.getHours();
    const minute = d.getMinutes();
    const second = d.getSeconds();
    const pad = num => (num < 10 ? '0' : '') + num;
    return format.replace(/([a-zA-Z])/g, (match, formatChar) => {
      switch (formatChar) {
        case 'Y':
          return year;
        case 'm':
          return pad(month);
        case 'd':
          return pad(day);
        case 'H':
          return pad(hour);
        case 'i':
          return pad(minute);
        case 's':
          return pad(second);
        default:
          return match;
      }
    });
  },
  /**
   * 切换主题
   * @param {*} themeName 
   */
  changeTheme(themeName = false) {
    if (!themeName) {
      let H = this.date('H');
      if (H >= 18 || H <= 6) {
        themeName = 'black';
      } else {
        themeName = 'white';
      }
      localStorage.setItem('theme_auto', true);
    } else {
      localStorage.setItem('theme_auto', false);
    }
    document.documentElement.setAttribute('theme', themeName);
    localStorage.setItem('theme', themeName);
  },
  /**
   * 开启自动切换主题
   * @param {*} theme 
   */
  openAutoDark() {
    //检测上一次打开是否设置固定主题
    let theme = localStorage.getItem('theme');
    if (localStorage.getItem('theme_auto') == 'false' && theme) {
      this.changeTheme(theme);
    }
    let inv = () => {
      if (localStorage.getItem('theme_auto') === null) {
        localStorage.setItem('theme_auto', true);
      }
      if (localStorage.getItem('theme_auto') == 'true') {
        this.changeTheme();
      }
    };
    inv();
    setInterval(() => {
      inv();
    }, 1800 * 1000);
  },
  Tsrc(w, b) {
    let theme = localStorage.getItem('theme');
    if (theme == 'white') {
      return w;
    } else {
      return b;
    }
  }
};