import $axios from 'axios'
import $config from '../../config/config'
export default {
  axios(options={},ret=()=>{}) {
    
    options.baseURL = $config.baseURL

    if(!options.headers){
      options.headers = {
        lang:localStorage.getItem('lang')
      }
    }
    
    let token = localStorage.getItem('token')

    $axios.defaults.baseURL = $config.baseURL;
    $axios.defaults.headers.common['token'] = token;
    
    $axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';


    console.log(options)
    $axios(options).then((res) => {
      console.log(res, 'Normal')
      if(res.status == 200){
        ret(res.data)
      }else if(res.status == 201){
        ret({
          code:1,
          msg:'Login Expired'
        })
        localStorage.removeItem('token')
        window.location.replace('#/home')
      }else{
        ret({
          code:1,
          msg:'Network Error'
        })
      }
      
    }).catch((e) => {
      console.log(e, 'Error')
      ret({
        code:1,
        msg:'Network Error.'
      })
      // alert(e.message)
    })

  }
}
