import "core-js/modules/es.array.push.js";
import Footer from '../components/Footer.vue';

import { showToast } from 'vant/es';
export default {
  components: {
    Footer
  },
  name: 'Login',
  data() {
    return {
      fromData: {
        email: '',
        password: ''
      }
    };
  },
  created() {},
  methods: {
    submit() {
      if (!this.fromData.email) {
        showToast(this.$L('请输入你的邮箱'));
        return;
      }
      if (!this.fromData.password) {
        showToast(this.$L('请输入你的密码'));
        return;
      }
      this.$api.login(this.fromData, res => {
        if (res.code == 0) {
          localStorage.setItem('token', res.data);
          showToast(this.$L('登录成功'));
          this.$router.push('/matching');
        } else {
          showToast(res.msg);
        }
      });
    }
  }
};