import "core-js/modules/es.array.push.js";
import { showToast } from 'vant';
import Footer from '../components/Footer.vue';
export default {
  components: {
    Footer
  },
  data() {
    return {
      yzm_int: 0,
      country: [],
      city: [],
      worklist: [],
      xinbie: [],
      fromData: {
        country_id: -1,
        city_id: -1,
        age: '',
        gender: -1,
        worklist_id: '',
        mobile: '',
        email: '',
        email_code: '',
        password: ''
      },
      password_cp: ''
    };
  },
  created() {
    this.xinbie = [{
      id: 1,
      name: this.$L('男')
    }, {
      id: 2,
      name: this.$L('女')
    }];
    this.getInfo();
  },
  methods: {
    sendCode() {
      if (this.yzm_int > 0) {
        return;
      }
      if (!this.fromData.email) {
        showToast(this.$L('请输入你的邮箱'));
        return;
      }
      if (this.fromData.email.indexOf('@') == -1) {
        showToast(this.$L('你的邮箱不正确'));
        return;
      }
      this.$api.sendCode({
        type: 1,
        email: this.fromData.email
      }, res => {
        if (res.code == 0) {
          this.yzm_int = 60;
          let inv = setInterval(() => {
            if (this.yzm_int == 0) {
              clearInterval(inv);
            } else {
              this.yzm_int--;
            }
          }, 1000);
          showToast(res.msg);
        } else {
          showToast(res.msg);
        }
      });
    },
    submit() {
      if (this.fromData.country_id == -1) {
        showToast(this.$L('请选择你的国家'));
        return;
      }
      if (this.fromData.city_id == -1) {
        showToast(this.$L('请选择你的地区'));
        return;
      }
      if (!this.fromData.age) {
        showToast(this.$L('请输入你的年龄'));
        return;
      }
      if (this.fromData.gender == -1) {
        showToast(this.$L('请选择你的性别'));
        return;
      }
      if (this.fromData.worklist_id == -1) {
        showToast(this.$L('请选择你的年收入'));
        return;
      }
      if (!this.fromData.mobile) {
        showToast(this.$L('请输入你的电话'));
        return;
      }
      if (!this.fromData.email) {
        showToast(this.$L('请输入你的邮箱'));
        return;
      }
      if (!this.fromData.email_code) {
        showToast(this.$L('请输入邮箱验证码'));
        return;
      }
      if (!this.fromData.password) {
        showToast(this.$L('请输入你的密码'));
        return;
      }
      if (this.fromData.password.length < 6) {
        showToast(this.$L('密码不能少于6位数'));
        return;
      }
      if (!this.password_cp) {
        showToast(this.$L('请输入你的确认密码'));
        return;
      }
      if (this.fromData.password != this.password_cp) {
        showToast(this.$L('你输入的两次密码不一致'));
        return;
      }
      let pushData = Object.assign({}, this.fromData);
      pushData.country_id = this.country[pushData.country_id].id;
      this.$api.register(pushData, res => {
        if (res.code == 0) {
          localStorage.setItem('token', res.data);
          showToast(this.$L('注册成功'));
          this.$router.push('/matching');
        } else {
          showToast(res.msg);
        }
      });
    },
    changeCountry(event) {
      // 获取选中的值
      const selectedValue = event.target.value;
      let find = this.country[selectedValue];
      console.log(find);
      this.$api.getCity({
        country: find.id
      }, res => {
        if (res.code == 0) {
          this.city_id = -1;
          this.city = res.data;
        } else {
          alert('Network anomaly');
        }
      });
    },
    getInfo() {
      this.$api.getCountry({}, res => {
        if (res.code == 0) {
          this.country = res.data;
        } else {
          alert('Network anomaly');
        }
      });
      this.$api.getWorklist({}, res => {
        if (res.code == 0) {
          this.worklist = res.data;
        } else {
          alert('Network anomaly');
        }
      });
    }
  }
};