import "core-js/modules/es.array.push.js";
import Footer from '../components/Footer.vue';
export default {
  components: {
    Footer
  },
  name: 'Home',
  data() {
    return {
      info: false,
      pclist: [],
      mlist: [],
      showLang: false
    };
  },
  created() {
    if (localStorage.getItem('token')) {
      this.$router.push('/matching');
    } else {
      this.getInfo();
    }
  },
  methods: {
    changeLang(lang) {
      this.$Lang.change(lang);
    },
    getInfo() {
      this.$api.getIndexShow({}, res => {
        if (res.code == 0) {
          this.info = res.data;
          let arr = [];
          let marr = [];
          console.log(res.data.anli);
          for (let index = 0; index < res.data.anli.length; index++) {
            const element = res.data.anli[index];
            if (arr.length == 6) {
              this.pclist.push(arr);
              arr = [];
            }
            if (marr.length == 2) {
              this.mlist.push(marr);
              marr = [];
            }
            arr.push(element);
            marr.push(element);
          }
          if (arr) {
            this.pclist.push(arr);
          }
          if (marr) {
            this.mlist.push(marr);
          }
          console.log(this.pclist);
        } else {
          alert('Network anomaly');
        }
      });
    }
  }
};