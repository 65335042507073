export default {
  "当前语言": "繁體中文",
  "语言": 'cn',
  "马上匹配": "馬上匹配",
  "我们致力于为高端单身人群，提供更好的配对服务": "我們致力於為高端單身人群，提供更好的配對服務",
  "为你寻找到适合你的人生伴侣": "為你尋找到適合你的人生伴侶",
  "我们已经成功为": "我們已經成功為",
  "人成功找到幸福": "人成功找到幸福",
  "欢迎注册": "歡迎註冊",
  "完成相关信息后快速匹配您心仪的伴侣": "完成相關信息後快速匹配您心儀的伴侶",
  "国家": "國家",
  "请选择你的国家": "請選擇你的國家",
  "地区": "地區",
  "年龄": "年齡",
  "请选择你的地区": "請選擇你的地區",
  "请输入你的年龄": "請輸入你的年齡",
  "性别": "性別",
  "请选择你的性别": "請選擇你的性別",
  "工作收入": "工作收入",
  "请选择你的年收入": "請選擇你的年收入",
  "电话": "電話",
  "请输入你的电话": "請輸入你的電話",
  "邮箱": "郵箱",
  "请输入你的邮箱": "請輸入你的郵箱",
  "邮箱验证码": "郵箱驗證碼",
  "验证码": "驗證碼",
  "发送验证码": "發送驗證碼",
  "秒后获取": "秒後獲取",
  "请输入你的密码": "請輸入你的密碼",
  "请再次输入你的密码": "請再次輸入你的密碼",
  "确认密码": "確認密碼",
  "快速匹配": "快速匹配",
  "已有账号，去登录": "已有賬號，去登錄",
  "你的邮箱不正确": "你的郵箱不正確",
  "发送成功": "發送成功",
  "请选择你的国家": "請選擇你的國家",
  "请输入邮箱验证码": "請輸入郵箱驗證碼",
  "密码不能少于6位数": "密碼不能少於6位數",
  "请输入你的确认密码": "請輸入你的確認密碼",
  "注册成功": "註冊成功",
  "登录成功": "登錄成功",
  "恭喜您选择了心仪的伴侣": "恭喜您選擇了心儀的伴侶",
  "姓名": "姓名",
  "生日": "生日",
  "年龄": "年齡",
  "身高": "身高",
  "地区": "地區",
  "兴趣": "興趣",
  "获取WS号": "獲取WS號",
  "复制": "複製",
  "已成功为您匹配到合适的伴侣": "已成功為您匹配到合適的伴侶",
  "请选择伴侣": "請選擇伴侶",
  "确认选择后将无法修改": "確認選擇後將無法修改",
  "首页": "首頁",
  "网站使用条款": "網站使用條款",
  "隐私政策": "隱私政策",
  "法律条款": "法律條款",
  "Copyright © XXXX集团 版权所有": "Copyright © XXXX集團 版權所有",
  "没有账号，去注册": "沒有賬號，去註冊",
  "男": "男",
  "女": "女",
  "他": "他",
  "她": "她",
  "确认选择": "確認選擇",
  "确认": "確認",
  "取消": "取消",
  "密码": "密碼",
  "复制成功": "複製成功",
  "复制失败": "複製失敗",
  "他的WS号": "他的WS號",
  "她的WS号": "她的WS號",
"服务协议": "服務協議",
"隐私保护政策": "隱私保護政策",
"关于我们": "關於我們",
"客服邮箱":"客服郵箱",
}
