import { createRouter,createWebHashHistory } from 'vue-router'

export default{
    init(app){
        let routes = []
		//扫描目录
		let files = require.context('../../view',true,/\.vue$/)
		files.keys().map((item) => {
			let path = item.slice(1).replace(".vue", "").toLowerCase();
			let comp = files(item).default;
			
			// //重置首页路由
			// if(path == '/index/index'){
			// 	comp.path,path = '/'
			// }
			
			//插入路由
			routes.push({
				path: path,
				component: comp
			})
		})
        
		console.log(routes)

        const router = createRouter({
            history: createWebHashHistory(),
            routes,
        })

		router.beforeEach((to) => {
			window.scrollTo(0,0)
			if(to.path == '/'){
				//如果没有登录的话，进入引导页面
				router.push('/home');
				//如果登录了，直接进入首页
				return false
			}
		})

        app.use(router)
    }
}