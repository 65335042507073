import Footer from '../components/Footer.vue';
export default {
  components: {
    Footer
  },
  data() {
    return {
      info: false
    };
  },
  created() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      this.$api.ArticleGetDetail({
        id: this.$route.query.id
      }, res => {
        if (res.code == 0) {
          this.info = res.data;
        } else {
          alert('Network anomaly');
        }
      });
    }
  }
};