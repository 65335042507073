export default {
  "当前语言": "русск",
  "语言": 'rus',
  "马上匹配": "Совпасть",
  "我们致力于为高端单身人群，提供更好的配对服务": "Мы стремимся предоставлять лучшие услуги по подбору для высококлассных одиночек",
  "为你寻找到适合你的人生伴侣": "Найди своего жизненного партнера",
  "我们已经成功为": "Мы успешно соединили",
  "人成功找到幸福": "человек с их родственными душами",
  "欢迎注册": "Добро пожаловать в регистрацию",
  "完成相关信息后快速匹配您心仪的伴侣": "Быстро найдите своего идеального партнера после заполнения соответствующей информации",
  "国家": "Страна",
  "请选择你的国家": "Пожалуйста, выберите вашу страну",
  "地区": "Регион",
  "年龄": "Возраст",
  "请选择你的地区": "Пожалуйста, выберите ваш регион",
  "请输入你的年龄": "Пожалуйста, введите ваш возраст",
  "性别": "Пол",
  "请选择你的性别": "Пожалуйста, выберите ваш пол",
  "工作收入": "Доход",
  "请选择你的年收入": "Пожалуйста, выберите ваш годовой доход",
  "电话": "Телефон",
  "请输入你的电话": "Пожалуйста, введите ваш номер телефона",
  "邮箱": "Электронная почта",
  "请输入你的邮箱": "Введите email",
  "邮箱验证码": "Код email",
  "验证码": "Код подтверждения",
   "发送验证码": "Отпр. код",
  "秒后获取": "сек. до",
  "密码": "Пароль",
  "请输入你的密码": "Пожалуйста, введите ваш пароль",
  "请再次输入你的密码": "Пожалуйста, введите ваш пароль еще раз",
  "确认密码": "Подтвердите пароль",
  "快速匹配": "совм.",
  "已有账号，去登录": "Уже есть аккаунт, войти",
  "你的邮箱不正确": "Ваш электронный адрес неверен",
  "发送成功": "Отправлено успешно",
  "请输入邮箱验证码": "Пожалуйста, введите код подтверждения электронной почты",
  "密码不能少于6位数": "Пароль должен быть не менее 6 символов",
  "请输入你的确认密码": "Пожалуйста, введите ваш пароль для подтверждения",
  "注册成功": "Регистрация прошла успешно",
  "登录成功": "Вход выполнен успешно",
  "恭喜您选择了心仪的伴侣": "Поздравляем с выбором идеального партнера",
  "姓名": "Имя",
  "生日": "День рождения",
  "身高": "Рост",
  "兴趣": "Интересы",
  "获取WS号": "Получить WS",
  "复制": "Копировать",
  "已成功为您匹配到合适的伴侣": "Мы успешно подобрали вам подходящего партнера",
  "请选择伴侣": "Пожалуйста, выберите партнера",
  "确认选择后将无法修改": "После подтверждения выбора изменить нельзя",
  "首页": "Главная",
  "网站使用条款": "Условия использования",
  "隐私政策": "Политика конфиденциальности",
  "法律条款": "Юридические условия",
  "Copyright © XXXX集团 版权所有": "Copyright © XXXX Group. Все права защищены.",
  "没有账号，去注册": "Нет аккаунта, зарегистрироваться",
  "男": "Мужчина",
  "女": "Женщина",
  "他": "Он",
  "她": "Она",
  "确认选择": "Подтвердить",
  "确认": "Подтвердить",
  "取消": "Отменить",
  "复制成功": "Копирование успешно",
  "复制失败": "Ошибка копирования",
  "他的WS号": "Его WS номер",
  "她的WS号": "Её WS номер",
  "服务协议": "Соглашение о сервисе",
  "隐私保护政策": "Политика конфиденциальности",
  "关于我们": "О нас",
  "客服邮箱":"Служба поддержки",
}