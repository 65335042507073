/**
 * 1rem = 100px
 */
export default {
    /**
     * 初始化 REM 布局
     * @param {number} designWidth - 设计稿宽度
     * @param {number} maxScale - 最大缩放比例
     */
    init(designWidth = 1200, maxScale = 100) {
        this.designWidth = designWidth;
        this.maxScale = maxScale;
        this.ScreenSize();
        this.setRem();
    },
    /**
     * 监听屏幕变化
     */
    ScreenSize() {
        window.addEventListener('resize', () => {
            this.setRem();
        });
    },
    setRem(){ 
        if(window.innerWidth<=768){
            this.designWidth = 375;
        }else{
            this.designWidth = 1200
        }
        console.log(this.designWidth)
        let scale = (window.innerWidth / this.designWidth) * 100;
        if (scale > this.maxScale) {
            scale = this.maxScale;
        }
        document.documentElement.style.fontSize = `${scale}px`;
        document.documentElement.style.height = `${window.innerHeight}px`;
    }
}