import Rem from '../src/lib/lib/rem';
export default {
  name: 'App',
  components: {},
  created() {
    // //全局像素大小配置
    Rem.init();
    // //全局主题日夜自动切换
    // this.$F.openAutoDark();
    //给函数库拿到VUE实例
    this.$F.init(this);
  },
  methods: {}
};