import $request from './request'
export default{
    getIndexShow(data,ret = ()=>{}){
        $request.axios({
            url:'/api/login/getIndexShow',
            method: 'POST',
            params:{},
            data,
        },ret)
    },
    ArticleGetDetail(data,ret = ()=>{}){
        $request.axios({
            url:'/baseapi/Article/getDetail',
            method: 'POST',
            params:{},
            data,
        },ret)
    },
    getCountry(data,ret = ()=>{}){
        $request.axios({
            url:'/api/Login/getCountry',
            method: 'POST',
            params:{},
            data,
        },ret)
    },
    getCity(data,ret = ()=>{}){
        $request.axios({
            url:'/api/Login/getCity',
            method: 'POST',
            params:{},
            data,
        },ret)
    },
    getWorklist(data,ret = ()=>{}){
        $request.axios({
            url:'/api/Login/getWorklist',
            method: 'POST',
            params:{},
            data,
        },ret)
    },
    register(data,ret = ()=>{}){
        $request.axios({
            url:'/api/Login/register',
            method: 'POST',
            params:{},
            data,
        },ret)
    },
    sendCode(data,ret = ()=>{}){
        $request.axios({
            url:'/api/Login/sendEmail',
            method: 'POST',
            params:{},
            data,
        },ret)
    },
    login(data,ret = ()=>{}){
        $request.axios({
            url:'/api/Login/login',
            method: 'POST',
            params:{},
            data,
        },ret)
    },
    getUserInfo(data,ret = ()=>{}){
        $request.axios({
            url:'/api/Love/getInfo',
            method: 'POST',
            params:{},
            data,
        },ret)
    },
    getLoves(data,ret = ()=>{}){
        $request.axios({
            url:'/api/Love/getLoves',
            method: 'POST',
            params:{},
            data,
        },ret)
    },
    setLove(data,ret = ()=>{}){
        $request.axios({
            url:'/api/Love/setLove',
            method: 'POST',
            params:{},
            data,
        },ret)
    },
    getLoveInfo(data,ret = ()=>{}){
        $request.axios({
            url:'/api/Love/getLoveInfo',
            method: 'POST',
            params:{},
            data,
        },ret)
    },
    getWs(data,ret = ()=>{}){
        $request.axios({
            url:'/api/Love/getWs',
            method: 'POST',
            params:{},
            data,
        },ret)
    },
}