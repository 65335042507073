export default {
  "当前语言": "한국어",
  "语言": 'kor',
  "马上匹配": "지금 매칭",
  "我们致力于为高端单身人群，提供更好的配对服务": "우리는 고급 싱글을 위해 더 나은 매칭 서비스를 제공하기 위해 헌신하고 있습니다",
  "为你寻找到适合你的人生伴侣": "당신에게 적합한 인생의 반려자를 찾아드립니다",
  "我们已经成功为": "우리는 이미 성공적으로",
  "人成功找到幸福": "사람들이 행복을 찾았습니다",
  "欢迎注册": "회원가입을 환영합니다",
  "完成相关信息后快速匹配您心仪的伴侣": "관련 정보를 완료하면 빠르게 이상적인 반려자를 매칭해드립니다",
  "国家": "국가",
  "请选择你的国家": "당신의 국가를 선택해주세요",
  "地区": "지역",
  "年龄": "나이",
  "请选择你的地区": "당신의 지역을 선택해주세요",
  "请输入你的年龄": "당신의 나이를 입력해주세요",
  "性别": "성별",
  "请选择你的性别": "당신의 성별을 선택해주세요",
  "工作收入": "직업 소득",
  "请选择你的年收入": "당신의 연 소득을 선택해주세요",
  "电话": "전화",
  "请输入你的电话": "당신의 전화번호를 입력해주세요",
  "邮箱": "이메일",
  "请输入你的邮箱": "당신의 이메일을 입력해주세요",
  "邮箱验证码": "이메일 인증코드",
  "验证码": "인증코드",
  "发送验证码": "코드 발송",
  "秒后获取": "초 후 받기",
  "请输入你的密码": "당신의 비밀번호를 입력해주세요",
  "请再次输入你的密码": "비밀번호를 다시 입력해주세요",
  "确认密码": "비밀번호 확인",
  "快速匹配": "빠른 매칭", 
  "已有账号，去登录": "이미 계정이 있으신가요? 로그인",
  "你的邮箱不正确": "당신의 이메일이 올바르지 않습니다",
  "发送成功": "발송 성공",
  "请选择你的国家": "당신의 국가를 선택해주세요",
  "请输入邮箱验证码": "이메일 인증코드를 입력해주세요",
  "密码不能少于6位数": "비밀번호는 6자리 이상이어야 합니다",
  "请输入你的确认密码": "확인 비밀번호를 입력해주세요",
  "注册成功": "회원가입 성공",
  "登录成功": "로그인 성공",
  "恭喜您选择了心仪的伴侣": "이상적인 반려자를 선택하신 것을 축하드립니다",
  "姓名": "이름",
  "生日": "생일",
  "密码": "암호",
  "年龄": "나이",
  "身高": "키",
  "地区": "지역",
  "兴趣": "취미",
  "获取WS号": "WS받기",
  "复制": "복사",
  "已成功为您匹配到合适的伴侣": "당신에게 적합한 반려자를 성공적으로 매칭해드렸습니다",
  "请选择伴侣": "반려자를 선택해주세요",
  "确认选择后将无法修改": "선택을 확인한 후에는 수정할 수 없습니다",
  "首页": "홈페이지",
  "网站使用条款": "웹사이트 이용약관",
  "隐私政策": "개인정보 보호정책",
  "法律条款": "법률 조항",
  "Copyright © XXXX集团 版权所有": "저작권 © XXXX 그룹, 무단 전재 금지",
  "没有账号，去注册": "계정이 없으신가요? 회원가입",
  "男": "남",
  "女": "여",
  "他": "그",
  "她": "그녀",
  "确认选择": "선택 확인",
  "确认": "확인",
  "取消": "취소",
  "复制成功": "복사 성공",
  "复制失败": "복사 실패",
  "他的WS号": "그의 WS 번호",
  "她的WS号": "그녀의 WS 번호",
  "服务协议": "서비스 약관",
  "隐私保护政策": "개인정보 보호정책",
  "关于我们": "회사 소개",
  "客服邮箱":"고객 서비스 이메일",
}