import { createApp } from 'vue'
import Use from '../lib/use'
import Properties from '../lib/properties'
import Router from '../lib/router'
import App from '../../App.vue'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
 
export default{
    run(){
        const app = createApp(App);
        //use集合
        Use.init(app);
        //注册全局挂载对象
        Properties.init(app);
        //处理路由
        Router.init(app);
        //启动页面
        app.mount('#app')
    }
}