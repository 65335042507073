import cn from './lang/cn'
import en from './lang/en'
import rus from './lang/rus'
import esp from './lang/esp'
import jpn from './lang/jpn'
import hin from './lang/hin'
import kor from './lang/kor'



let LANG = {
    lang:'en', //默认
    langData:{
        cn,
        en,
        rus,
        esp,
        kor,
        hin,
        jpn
    },
    // navLang:{
    //     'zh':'cn',
    //     'en':'en',
    // },
    init(){
        let l = localStorage.getItem('lang');
        if(l){
            this.lang = l
        }else{
            //檢測設備語言
            // if(LANG.getLanguage() == 'Chinese'){
            //     this.lang = 'cn'
            // }else{
            //     this.lang = 'en'
            // }
            localStorage.setItem('lang',this.lang);   
            // let myNavLang = navigator.language.split('-')[0].toLowerCase();
            // if(this.navLang[myNavLang]){
            //     localStorage.setItem('lang',this.navLang[myNavLang]); 
            // }else{
            //     localStorage.setItem('lang',this.lang);     
            // }
        }
    },
    getLanguage() {
        const language = navigator.language || navigator.userLanguage; // 获取系统语言
        if (language.startsWith('zh')) { // 检查是否是中文
            return 'Chinese'; // 返回中文
        } else {
            return 'English'; // 如果不是中文，返回英文
        }
    },
    /**
     * 獲取文字對應語言文字
     * @param {*} name 
     * @returns 
     */
    get(name){
        console.log(LANG.langData,LANG.lang,name)
        let text = LANG.langData[LANG.lang][name];
        if(!text){
            console.log(`${LANG.lang}-缺失: "${name}":"${name}"`);
            text = name
        }
        return text;
    },
    /**
     * 切換語言
     * @param {*} name 
     */
    change(name){
        this.lang = name
        localStorage.setItem('lang',name);
        window.location.reload()
    }
}

export default LANG;