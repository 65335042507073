export default {
  "当前语言": "हिंदी",
  "语言": 'hin',
  "马上匹配": "अभी मेल करें",
  "我们致力于为高端单身人群，提供更好的配对服务": "हम उच्च-स्तरीय एकल के लिए बेहतर मेल सेवा प्रदान करने के लिए प्रतिबद्ध हैं",
  "为你寻找到适合你的人生伴侣": "अपने जीवन साथी को खोजें",
  "我们已经成功为": "हमने सफलतापूर्वक मिलाया है",
  "人成功找到幸福": "व्यक्तियों को उनके जीवनसाथी के साथ",
  "欢迎注册": "पंजीकरण में आपका स्वागत है",
  "完成相关信息后快速匹配您心仪的伴侣": "संबंधित जानकारी पूरी करने के बाद जल्दी से अपने आदर्श साथी को खोजें",
  "国家": "देश",
  "请选择你的国家": "कृपया अपना देश चुनें",
  "地区": "क्षेत्र",
  "年龄": "उम्र",
  "请选择你的地区": "कृपया अपना क्षेत्र चुनें",
  "请输入你的年龄": "कृपया अपनी उम्र दर्ज करें",
  "性别": "लिंग",
  "请选择你的性别": "कृपया अपना लिंग चुनें",
  "工作收入": "आय",
  "请选择你的年收入": "कृपया अपनी वार्षिक आय चुनें",
  "电话": "फोन",
  "请输入你的电话": "कृपया अपना फोन नंबर दर्ज करें",
  "邮箱": "ईमेल",
  "请输入你的邮箱": "कृपया अपना ईमेल दर्ज करें",
  "邮箱验证码": "ईमेल सत्यापन कोड",
  "验证码": "सत्यापन कोड",
  "发送验证码": "कोड भेजें",
  "秒后获取": "से. में प्राप्त",
  "密码": "पासवर्ड",
  "请输入你的密码": "कृपया अपना पासवर्ड दर्ज करें",
  "请再次输入你的密码": "कृपया अपना पासवर्ड फिर से दर्ज करें",
  "确认密码": "पासवर्ड की पुष्टि करें",
  "快速匹配": "तेज़ मेल",
  "已有账号，去登录": "पहले से एक खाता है, लॉगिन करें",
  "你的邮箱不正确": "आपका ईमेल गलत है",
  "发送成功": "सफलतापूर्वक भेजा गया",
  "请输入邮箱验证码": "कृपया ईमेल सत्यापन कोड दर्ज करें",
  "密码不能少于6位数": "पासवर्ड कम से कम 6 अंकों का होना चाहिए",
  "请输入你的确认密码": "कृपया अपनी पुष्टि पासवर्ड दर्ज करें",
  "注册成功": "पंजीकरण सफल हुआ",
  "登录成功": "लॉगिन सफल",
  "恭喜您选择了心仪的伴侣": "अपने आदर्श साथी को चुनने के लिए बधाई",
  "姓名": "नाम",
  "生日": "जन्मदिन",
  "身高": "ऊंचाई",
  "兴趣": "रुचियाँ",
  "获取WS号": "WS प्राप्त",
  "复制": "कॉपी करें",
  "已成功为您匹配到合适的伴侣": "हमने आपके लिए उपयुक्त साथी को सफलतापूर्वक मिलाया है",
  "请选择伴侣": "कृपया साथी चुनें",
  "确认选择后将无法修改": "चयन की पुष्टि के बाद आप संशोधित नहीं कर सकते",
  "首页": "होम",
  "网站使用条款": "उपयोग की शर्तें",
  "隐私政策": "गोपनीयता नीति",
  "法律条款": "कानूनी शर्तें",
  "Copyright © XXXX集团 版权所有": "कॉपीराइट © XXXX समूह. सर्वाधिकार सुरक्षित.",
  "没有账号，去注册": "खाता नहीं है, पंजीकरण करें",
  "男": "पुरुष",
  "女": "महिला",
  "他": "वह",
  "她": "वह",
  "确认选择": "पुष्टि करें",
  "确认": "पुष्टि करें",
  "取消": "रद्द करें",
  "复制成功": "कॉपी सफल",
  "复制失败": "कॉपी विफल",
  "他的WS号": "उसका WS नंबर",
  "她的WS号": "उसका WS नंबर",
  "服务协议": "सेवा अनुबंध",
  "隐私保护政策": "गोपनीयता नीति",
  "关于我们": "हमारे बारे में",
  "客服邮箱":"ग्राहक सेवा ईमेल",
}