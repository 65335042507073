export default {
    "当前语言":"English",
    "语言": 'en',
    "马上匹配": "Match Now",
    "我们致力于为高端单身人群，提供更好的配对服务": "We are committed to providing better matchmaking services for high-end singles",
    "为你寻找到适合你的人生伴侣": "Find your life partner",
    "我们已经成功为": "We've successfully matched",
    "人成功找到幸福": "individuals with their soulmates",
    "欢迎注册": "Welcome to register ",
    "完成相关信息后快速匹配您心仪的伴侣": "Quickly match your ideal partner after completing relevant information",
    "国家": "Country",
    "请选择你的国家": "Please select your country",
    "地区": "Region",
    "年龄": "Age",
    "请选择你的地区": "Please select your region",
    "请输入你的年龄": "Please  your age",
    "性别": "Gender",
    "请选择你的性别": "Please select your gender",
    "工作收入": "Income",
    "请选择你的年收入": "Please select your annual income",
    "电话": "Phone",
    "请输入你的电话": " Enter your phone number",
    "邮箱": "Email",
    "请输入你的邮箱": "Please enter your email",
    "邮箱验证码": "Email  code",
    "验证码": " code",
    "发送验证码": "Send code",
    "秒后获取": " after get",
    "密码": "password",
    "请输入你的密码": "Please enter your password",
    "请再次输入你的密码": "Please enter your password again",
    "确认密码": "Confirm password",
    "快速匹配": "matching",
    "已有账号，去登录": "Already have an account, go to login",
    "你的邮箱不正确": "Your email is incorrect",
    "发送成功": "Sent successfully",
    "请输入邮箱验证码": "Please enter email verification code",
    "密码不能少于6位数": "Password must be at least 6 characters long",
    "请输入你的确认密码": "Please enter your confirm password",
    "注册成功": "Registration successful",
    "登录成功": "Login successful",
    "恭喜您选择了心仪的伴侣": "Congratulations on choosing your ideal partner",
    "姓名": "Name",
    "生日": "Birthday",
    "身高": "Height",
    "兴趣": "Interests",
    "获取WS号": "Get WS ",
    "复制": "Copy",
    "已成功为您匹配到合适的伴侣": "Successfully matched suitable partner for you",
    "请选择伴侣": "Please select partner",
    "确认选择后将无法修改": "You cannot modify after confirming selection",
    "首页": "Home",
    "网站使用条款": "Terms of Use",
    "隐私政策": "Privacy Policy",
    "法律条款": "Legal Terms",
    "Copyright © XXXX集团 版权所有": "Copyright © XXXX Group. All rights reserved.",
    "没有账号，去注册": "No account, go register",
    "男": "Man",
    "女": "Women",
    "他": "He",
    "她": "She",
    "确认选择": "Confirm",
    "确认": "Confirm",
    "取消": "cancel",
    "复制成功": "Copy Successful",
    "复制失败": "Copy Failed",
    "他的WS号": "His WS Number",
    "她的WS号": "Her WS Number",
    "服务协议": "Service Agreement",
    "隐私保护政策": "Privacy Policy",
    "关于我们": "About Us",
    "客服邮箱":"Customer Service Email",
}
