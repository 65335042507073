export default {
  "当前语言": "español",
  "语言": 'esp',
  
  "我们致力于为高端单身人群，提供更好的配对服务": "Estamos comprometidos a proporcionar mejores servicios de emparejamiento para solteros de alto nivel",
  "为你寻找到适合你的人生伴侣": "Encuentra tu pareja de vida",
  "我们已经成功为": "Hemos emparejado con éxito a",
  "人成功找到幸福": "personas con sus almas gemelas",
  "欢迎注册": "Bienvenido a registrarte",
  "完成相关信息后快速匹配您心仪的伴侣": "Empareja rápidamente a tu pareja ideal después de completar la información relevante",
  "国家": "País",
  "请选择你的国家": "Por favor selecciona tu país",
  "地区": "Región",
  "年龄": "Edad",
  "请选择你的地区": "Por favor selecciona tu región",
  "请输入你的年龄": "Por favor ingresa tu edad",
  "性别": "Género",
  "请选择你的性别": "Por favor selecciona tu género",
  "工作收入": "Ingresos",
  "请选择你的年收入": "Por favor selecciona tus ingresos anuales",
  "电话": "Teléfono",
  "请输入你的电话": "Por favor ingresa tu número de teléfono",
  "邮箱": "Correo electrónico",
  "请输入你的邮箱": "Por favor ingresa tu correo electrónico",
  
  "马上匹配": "Emparejar",
  "邮箱验证码": "Código correo",
  "验证码": "Código",
  "发送验证码": "Enviar",
  "秒后获取": "seg. para obtener",
  "获取WS号": "Obtener WS",
  "快速匹配": "Emparejar",
  "请输入邮箱验证码": "Ingresa código correo",
  "密码不能少于6位数": "Contraseña ≥ 6 caracteres",
  "请输入你的确认密码": "Ingresa confirmación",
  "请再次输入你的密码": "Reingresa contraseña",
  
  "密码": "Contraseña",
  "请输入你的密码": "Por favor ingresa tu contraseña",
 
  "确认密码": "Confirmar contraseña",
  
  "已有账号，去登录": "Ya tienes una cuenta, inicia sesión",
  "你的邮箱不正确": "Tu correo electrónico es incorrecto",
  "发送成功": "Enviado con éxito",
 
  "注册成功": "Registro exitoso",
  "登录成功": "Inicio de sesión exitoso",
  "恭喜您选择了心仪的伴侣": "Felicitaciones por elegir a tu pareja ideal",
  "姓名": "Nombre",
  "生日": "Cumpleaños",
  "身高": "Altura",
  "兴趣": "Intereses",
  
  "复制": "Copiar",
  "已成功为您匹配到合适的伴侣": "Hemos emparejado con éxito a una pareja adecuada para ti",
  "请选择伴侣": "Por favor selecciona una pareja",
  "确认选择后将无法修改": "No puedes modificar después de confirmar la selección",
  "首页": "Inicio",
  "网站使用条款": "Términos de uso",
  "隐私政策": "Política de privacidad",
  "法律条款": "Términos legales",
  "Copyright © XXXX集团 版权所有": "Copyright © XXXX Group. Todos los derechos reservados.",
  "没有账号，去注册": "No tienes cuenta, regístrate",
  "男": "Hombre",
  "女": "Mujer",
  "他": "Él",
  "她": "Ella",
  "确认选择": "Confirmar",
  "确认": "Confirmar",
  "取消": "Cancelar",
  "复制成功": "Copia exitosa",
  "复制失败": "Error al copiar",
  "他的WS号": "Su número WS",
  "她的WS号": "Su número WS",
  "服务协议": "Acuerdo de servicio",
  "隐私保护政策": "Política de privacidad",
  "关于我们": "Sobre nosotros",
  "客服邮箱":"Correo de atención al cliente",
}
